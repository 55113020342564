<template>
  <v-dialog
    v-model="isModalOpen"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title class="text-h3 font-weight-medium">
        {{ $t("stockAdjustments.validate") }}
      </v-card-title>
      <v-card-text>
        {{
          $t("stockAdjustments.validate_text", {
            tracking_number: adjustmentItem.tracking_number,
          })
        }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          @click="close()"
          color="indigo"
          text
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          class="primary"
          :loading="isLoading"
          :disabled="isLoading"
          @click="confirm()"
          text
        >
          {{ $t("stockAdjustments.validate") }}</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import stockAdjustmentService from "@/store/services/stock-adjustment-service";
export default {
  props: {
    isModalOpen: Boolean,
    toggleModal: Function,
    adjustmentItem: Object,
    adjustmentListFilters: Object,
    return_to_root:String,
  },
  mounted: function () {
    this.currentItem = { ...this.adjustmentItem };
  },
  data() {
    return {
      currentItem: null,
      isLoading: false,
    };
  },
  methods: {
    close() {
      this.toggleModal();
    },
    async confirm() {
      try {
        this.isLoading = true;
        await stockAdjustmentService.validate(this.currentItem);
        this.toggleModal();
        await this.$store.dispatch("stockAdjustments/list", {
          ...this.adjustmentListFilters,
        });
        this.$store.dispatch(
          "alerts/success",
          this.$t("stockAdjustments.validated_successfully")
        );
        this.isLoading = false;
        if(this.return_to_root!=null){
          this.$router.push("/"+this.return_to_root);
        }
      } catch (error) {
        this.isLoading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>